import RoutePath from 'utils/RoutePath';

export default {
  dashboard: new RoutePath('/dashboard/'),

  user: new RoutePath('/user/account-profile/profile1/'),

  rotations: new RoutePath('/rotations/'),
  rotationDetails: new RoutePath('/rotations/:uuid/'),

  // database: new RoutePath('/database/'),
  preceptorsOld: new RoutePath('/database/preceptors/'),
  studentsOld: new RoutePath('/database/students/'),
  databasePreceptorViewOld: new RoutePath('/database/preceptors/:uuid/'),
  databaseStudentViewOld: new RoutePath('/database/students/:uuid/'),

  preceptors: new RoutePath('/preceptors/'),
  students: new RoutePath('/students/'),
  databaseStudentView: new RoutePath('/students/:uuid/'),
  databasePreceptorView: new RoutePath('/preceptors/:uuid/'),

  requests: new RoutePath('/requests/'),
  request: new RoutePath('/request/:uuid/'),

  reminders: new RoutePath('/reminders/'),

  reports: new RoutePath('/reports/'),

  settingsGeneral: new RoutePath('/settings/general/'),

  settingsFieldsListView: new RoutePath('/settings/fields/'),

  settingsWizardListView: new RoutePath('/settings/profiles/list'),
  settingsWizardDetailView: new RoutePath('/settings/profile/:uuid/'),

  settingsTags: new RoutePath('/settings/tags/'),

  settingsStateOwnership: new RoutePath('/settings/state-ownership/'),

  settingsUsers: new RoutePath('/settings/users/'),

  notifications: new RoutePath('/notifications/'),
  adminProfile: new RoutePath('/profile'),
};
